/* This CSS will print because %message-shared is extended. */
%button-shared {
  border-radius: 8px;
  padding: 12px 24px;
  @media (max-width: 500px) {
    padding: 12px 18px;
  }
  @media (max-width: 400px) {
    padding: 9px 12px;
  }
}

%buttonOutline-shared {
  border-radius: 8px;
  padding: 10px 20px;
  border: solid;
}

button.btn-default {
  @extend %button-shared;
  background-color: #270058;
  color: white;
}

button.btn-outline {
  @extend %buttonOutline-shared;
}

button.btnOut-red {
  @extend %buttonOutline-shared;
  border-color: #dd3730;
  color: #dd3730;
}

button.btn-disabled {
  @extend %button-shared;
  background-color: #edeef2;
  color: #aeaeae;
}

button.btn-white {
  @extend %button-shared;
  background-color: white;
  color: #41008b;
}
