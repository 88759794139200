
  .accordion-item {
    list-style: none;
  }
  .accordion-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5em;
  }

  .accordion-toggle h3 {
    margin: 0;
  }
  .accordion-content {
    padding: 1em;
  }

  span img{
    display: flex;
    position: absolute;
    left: 82%;
  }

 
@media screen and (min-width : 1024px) {
  span img{
    display: flex;
    position: absolute;
    left: 75%;
  }
}

@media screen and (min-width : 2560px) {
  span img{
    display: flex;
    position: absolute;
    left: 71%;
  }
}