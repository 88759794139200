div.index nav{
    // background-color: transparent;
}

div.indexbg{
    background-image: url('../../../assets/imgs/zedintro-hero-img.png');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 700px) {
        background-position: 30% 105%;
    }
}

.categories{
    background-color: #F9F5FF;
}
