@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
* {
  font-family: 'Inter';
}

html {
  // max-width: 1600px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.search nav {
  background-color: #41008b;
}

.star-ratings {
  span {
    padding: 0rem 0.1rem;
  }
}

.star-rate {
  span {
    // color: #ffd700;
    padding: 0rem 0.3rem;
  }
}

.card {
  background-color: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  // height: 25.5rem;
}

// section,
// nav {
//   padding-left: 2.5rem;
//   padding-right: 2.5rem;
// }

nav {
  // max-width: 1600px;
  .active {
    text-decoration: underline;
    text-decoration-color: #a855f7;
    text-underline-offset: 8px;
  }
}

.altNav {
  min-height: 100vh;
  nav.bg-white {
    background-color: #1e293b !important;

    a {
      color: white !important;
    }

    a.bg-black {
      background-color: white !important;
      color: black !important;
    }
  }
  background-color: #efefef;
}

.auth {
  .col-span-2 {
    background-image: url('../../assets/imgs/authbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: left;
    height: 100vh;
    color: white;
  }
}

.expert-auth {
  .col-span-2 {
    background-image: url('../../assets/imgs/expertauthbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: left;
    height: 100vh;
    color: white;
  }
}

.settingsbg {
  background-image: url('../../assets/imgs/settingsbg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
  // section,
  // nav {
  //   padding-left: 5rem;
  //   padding-right: 5rem;
  // }

  .star-ratings {
    span {
      padding: 0rem 0.7rem;
    }
  }

  .star-rate {
    span {
      // color: #ffd700;
      padding: 0rem 0.3rem;
    }
  }
}

// @media screen and (max-width: 700px) {
//   section,
//   nav {
//     padding-left: 1.5rem;
//     padding-right: 1.5rem;
//   }
// }
