@media screen and (min-width: 1024px) {
  footer .input {
    margin-left: 150%;
    margin-top: -3rem;
  }

  footer .links {
    margin-right: 50%;
    margin-left: -70%;
  }

  footer form {
    margin-top: -3.5rem;
  }

  footer input {
    height: 44px;
    width: 230px;
  }

  footer button {
    padding: 0px 18px 0px 18px !important;
    height: 44px;
    width: 114px;
  }

  footer .about {
    margin-top: -10rem;
  }
}
