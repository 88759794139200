.sidebar {
  .link {
    display: flex;
    color: black;
    margin: 2rem 0;
    font-size: 1rem;
    gap: 8px;
    width: 80%;
    padding: .7rem 1rem;
    svg {
      font-size: 1.2rem;
    }
  }

  .active {
    border-radius: 8px;
    color: black;
    font-weight: 600;
    text-decoration: none;
    width: 80%;
    padding: .7rem 1rem;
    background: white;
    box-shadow:
      0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);

    svg {
      color: rgba(147, 76, 253, 1);
      font-size: 1.3rem;
      font-weight: 900;
    }
  }
}
