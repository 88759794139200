@media screen and (min-width: 1024px) {
    .book{
        .maingrid{
            .container.firstChild {
                padding-left: 8rem;
              }
              .container.secondChild {
                // border-left: 1px solid #EAECF0;
                padding-left: 2rem;
                margin-left: -4rem;
              }
        }
    }
}

@media screen and (min-width: 1200px) {
    .book{
        .maingrid{
            .container.firstChild {
                padding-left: 8rem;
              }
              .container.secondChild {
                padding-left: 4rem;
                margin-left: -1rem;
              }
        }
    }
}

@media screen and (min-width: 1440px) {
    .book{
        .maingrid{
            .container.firstChild {
                padding-left: 12rem;
              }
              .container.secondChild {
                padding-left: 8rem;
              }
        }
    }
}