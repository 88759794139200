@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 30rem;
 height:  25rem;
}

.modal-2 {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 43rem;
 height:  24rem;
}

.requested{
  width: 8rem;
  height: 2rem;
  background-color: #FFEFD2;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .modal-2 {
    width: 30rem;
    height: 25rem;
  }
}